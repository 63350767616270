import { defineStore } from 'pinia';

export const useChatStore = defineStore('chat', {
  state: () => ({
    messages: [] as Array<{ id: number; text: string; type: 'sent' | 'received' }>
  }),

  actions: {
    addMessage(message: { id: number; text: string; type: 'sent' | 'received' }) {
      this.messages.push(message);
    },
    addReply(reply: { id: number; text: string; type: 'sent' | 'received' }) {
      this.messages.push(reply);
    }
  },

  getters: {
    getMessages: (state) => state.messages
  }
});
