<template>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" class="relative z-50" @close="close">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex items-center justify-center min-h-full p-4 text-center">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-4xl p-6 bg-white rounded-2xl shadow-lg">
                <div class="flex justify-between items-center mb-4">
                  <h3 class="text-lg font-medium text-gray-900">Complete your order</h3>
                  <button @click="close" class="text-primary hover:text-gray-500">
                    <span class="sr-only">Close</span>
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <p class="text-sm text-start text-gray-500">Get all access and an extra 20% off when you subscribe annually</p>

                <div class="grid grid-cols-2 gap-4 mt-4">
                  <div>
                    <label for="membership" class="block text-start text-sm font-medium text-gray-700">Membership type</label>
                    <select id="membership" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm">
                      <option>Agency Plus</option>
                    </select>
                    <div class="text-sm text-gray-500 mt-5">
                      <p class="text-start">For most businesses that want to optimize web queries</p>
                      <ul  class="mt-2 space-y-1">
                        <li  v-for="feature in productStore.listPackage.split('\n')" :key="feature"  class="flex items-center">
                          <i class="fas fa-check-circle text-primary mr-2"></i> {{ feature }}
                        </li>

                      </ul>
                    </div>
                  </div>

                  <div class="mt-0">
                    <label class="block text-start text-sm font-medium text-gray-700">Payment method</label>
                    <div class="mt-2 space-y-2">
                      <div v-for="method in displayedPaymentMethods" :key="method.id" :class="['flex items-center bg-gray-100 py-2 px-2 rounded-xl', { 'border-2 border-primary': selectedPaymentMethod === method.code }]">
                        <input  v-model="selectedPaymentMethod" type="radio" :id="method.code" name="payment" :value="method.code" class="form-radio text-primary">
                        <label :for="method.code" class="ml-2 flex items-center">
                          {{ method.display_name }} <img :src="'data:image/png;base64,' + method.image" :alt="method.display_name + ' logo'" class="ml-2 h-4">
                        </label>
                      </div>
                      <button v-if="showMoreButton" @click="toggleShowMore" class="text-primary underline">{{ showAllMethods ? 'Show less' : 'Show more' }}</button>
                    </div>
                  </div>
                </div>

                <div class="text-start mt-4 text-xl font-bold text-gray-900">
                  {{formatPrice(productStore.lstPrice)}} /{{formatDate(productStore.typeSubscribe)}}
                </div>

                <div class="mt-4 text-start">
                  <a href="#" class="text-primary underline">Details</a>
                </div>

                <div class="mt-6 flex justify-end">
                  <button @click="proceedToPayment" class="w-1/2 px-4 py-2 text-white bg-primary rounded-md">
                    Proceed to Payment
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>

  <script lang="ts" setup>
  import { ref, computed, onMounted } from 'vue';
  import { Dialog, DialogOverlay, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
  import { getPaymentMethod } from '~/server/api/apiService';
  import { useProductStore } from '@/stores/productStore';
  import { formatPrice, formatDate } from '@/utils/numberFormat';

  const productStore = useProductStore();
  const paymentMethods = ref([]);
  const showAllMethods = ref(false);
  const productSelected = ref({});
  const selectedPaymentMethod = ref(null);
  const props = defineProps({
    isOpen: {
      type: Boolean,
      required: true
    },
    product: {
        type: Object,
        required: true
    }
  });


  onMounted(async() => {
    await productStore.loadProduct()
    console.log('productStore',JSON.stringify(productStore))
    await fetchPaymentMethod();
  });

  const emit = defineEmits(['close']);

  const close = () => {
    emit('close');
  };

  const proceedToPayment = () => {
    // Handle payment process
  };

  const fetchPaymentMethod = async () => {
    try {
      const response = await getPaymentMethod({
        company_id: 0,
        limit: 0,
        offset: 0,
        name: '',
        midtrans_value: '',
        payment_method_id: 0,
      });
      productSelected.value = props.product
      console.log('Product received in modal:', props.product);
      paymentMethods.value = response.data;
      // Debugging log
    } catch (error) {
      console.error('Failed to fetch payment methods:', error);
    }
  };

  const displayedPaymentMethods = computed(() => {
    return showAllMethods.value ? paymentMethods.value : paymentMethods.value.slice(0, 6);
  });

  const showMoreButton = computed(() => {
    return paymentMethods.value.length > 6;
  });

  const toggleShowMore = () => {
    showAllMethods.value = !showAllMethods.value;
  };
  </script>

  <style scoped>
  /* Custom styles to match the design */
  .form-radio {
    border-radius: 50%;
    border: 1px solid #d1d5db; /* Example border color */
    width: 16px;
    height: 16px;
  }

  .form-radio:checked {
    border: 6px solid primary; /* Example checked color */
  }
  </style>
