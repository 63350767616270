// stores/productStore.ts
import { defineStore } from 'pinia';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const useProductStore = defineStore('product', {
  state: () => ({
    categId: '',
    companyId: '',
    detailedType: '',
    displayName: '',
    id: 0,
    listPackage: '',
    lstPrice: 0,
    productVariantImageIds: false,
    recurringInvoice: false,
    saleOk: false,
    taxesId: false,
    typeSubscribe: '',
    userCount: 0,
    features: [] as string[]
  }),

  actions: {
    setProduct(productData: {
      categ_id: string;
      company_id: string;
      detailed_type: string;
      display_name: string;
      id: number;
      list_package: string;
      lst_price: number;
      product_variant_image_ids: boolean;
      recurring_invoice: boolean;
      sale_ok: boolean;
      taxes_id: boolean;
      type_subscribe: string;
      user_count: number;
      features: string[];
    }) {
      this.categId = productData.categ_id;
      this.companyId = productData.company_id;
      this.detailedType = productData.detailed_type;
      this.displayName = productData.display_name;
      this.id = productData.id;
      this.listPackage = productData.list_package;
      this.lstPrice = productData.lst_price;
      this.productVariantImageIds = productData.product_variant_image_ids;
      this.recurringInvoice = productData.recurring_invoice;
      this.saleOk = productData.sale_ok;
      this.taxesId = productData.taxes_id;
      this.typeSubscribe = productData.type_subscribe;
      this.userCount = productData.user_count;
      this.features = productData.features;
      this.persistProduct();
    },

    clearProduct() {
      this.categId = '';
      this.companyId = '';
      this.detailedType = '';
      this.displayName = '';
      this.id = 0;
      this.listPackage = '';
      this.lstPrice = 0;
      this.productVariantImageIds = false;
      this.recurringInvoice = false;
      this.saleOk = false;
      this.taxesId = false;
      this.typeSubscribe = '';
      this.userCount = 0;
      this.features = [];
      this.persistProduct();
    },

    persistProduct() {
      const productData = {
        categ_id: this.categId,
        company_id: this.companyId,
        detailed_type: this.detailedType,
        display_name: this.displayName,
        id: this.id,
        list_package: this.listPackage,
        lst_price: this.lstPrice,
        product_variant_image_ids: this.productVariantImageIds,
        recurring_invoice: this.recurringInvoice,
        sale_ok: this.saleOk,
        taxes_id: this.taxesId,
        type_subscribe: this.typeSubscribe,
        user_count: this.userCount,
        features: this.features
      };
      const productDataString = JSON.stringify(productData);
      cookies.set('productStore', productDataString, { path: '/' });
    },

    loadProduct() {
      return new Promise<void>((resolve) => {
        const productData = cookies.get('productStore');
        if (productData) {
          try {
            const parsedData = typeof productData === 'string' ? JSON.parse(productData) : productData;
            this.categId = parsedData.categ_id;
            this.companyId = parsedData.company_id;
            this.detailedType = parsedData.detailed_type;
            this.displayName = parsedData.display_name;
            this.id = parsedData.id;
            this.listPackage = parsedData.list_package;
            this.lstPrice = parsedData.lst_price;
            this.productVariantImageIds = parsedData.product_variant_image_ids;
            this.recurringInvoice = parsedData.recurring_invoice;
            this.saleOk = parsedData.sale_ok;
            this.taxesId = parsedData.taxes_id;
            this.typeSubscribe = parsedData.type_subscribe;
            this.userCount = parsedData.user_count;
            this.features = parsedData.features;
          } catch (error) {
            console.error('Failed to parse product data from cookies:', error);
            this.clearProduct();
          }
        }
        resolve();
      });
    },

    signOut() {
      this.clearProduct();
      cookies.remove('productStore', { path: '/' });
    }
  },

  getters: {
    getProduct: (state) => ({
      categId: state.categId,
      companyId: state.companyId,
      detailedType: state.detailedType,
      displayName: state.displayName,
      id: state.id,
      listPackage: state.listPackage,
      lstPrice: state.lstPrice,
      productVariantImageIds: state.productVariantImageIds,
      recurringInvoice: state.recurringInvoice,
      saleOk: state.saleOk,
      taxesId: state.taxesId,
      typeSubscribe: state.typeSubscribe,
      userCount: state.userCount,
      features: state.features
    })
  }
});
