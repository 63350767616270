<template>
    <div>
      <!-- Navbar -->

      <div class="flex">
        <!-- Sidebar -->
        <aside class="w-64 bg-secondary text-white p-4 h-1/2 rounded-xl">
          <div class="flex items-center space-x-4 mb-4">
            <img src="/assets/images/profile-1.jpeg" alt="Profile" class="rounded-full">
            <div>
              <h2 class="text-lg text-white font-semibold">Jon doe</h2>
              <p class="text-sm hover:text-primary text-white">Consultant</p>
            </div>
          </div>
          <nav>
            <ul class="space-y-2  text-white">
              <li><a href="#" class="block p-2 text-white hover:text-primary rounded">Profile</a></li>
              <li><a href="#" class="block p-2 text-white hover:text-primary rounded">My Network</a></li>
              <li><a href="#" class="block p-2 text-white hover:text-primary rounded">Property</a></li>
              <li><a href="#" class="block p-2 text-white hover:text-primary rounded">Messaging</a></li>
              <li><a href="#" class="block p-2 text-white hover:text-primary rounded">Notifications</a></li>
              <li><a href="#" class="block p-2 text-white hover:text-primary rounded">Me</a></li>
            </ul>
          </nav>
        </aside>

        <!-- Main Content -->
        <main class="flex-1 px-4 rounded-xl">
          <section class="bg-white shadow p-4 mb-4 rounded-xl bg-primary">
            <div class="flex items-center space-x-4 mb-4">
              <img src="https://via.placeholder.com/40" alt="Profile" class="rounded-full">
              <input type="text" placeholder="Start a post, try writing with AI" class="flex-1 p-2 border rounded">
            </div>
            <div class="flex justify-around">
              <button class="flex items-center space-x-2 text-primary hover:text-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm1 2h10v2H5V5zm10 4H5v5h10V9z"/>
                </svg>
                <span>Media</span>
              </button>
              <button class="flex items-center space-x-2 text-primary hover:text-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M2 8a2 2 0 012-2h2.5a.5.5 0 010 1H4a1 1 0 00-1 1v6a1 1 0 001 1h1.293l.293-.293a1 1 0 01.707-.293H8a1 1 0 01.707.293l.293.293h3.586l.293-.293A1 1 0 0114 15h1a1 1 0 001-1V8a1 1 0 00-1-1h-1.5a.5.5 0 010-1H14a2 2 0 012 2v6a2 2 0 01-2 2h-1.5a.5.5 0 01-.354-.146L10 14.707a1 1 0 00-1.414 0L5.854 17.854A.5.5 0 015.5 18H4a2 2 0 01-2-2V8z" clip-rule="evenodd"/>
                </svg>
                <span>Property</span>
              </button>
              <button class="flex items-center space-x-2 text-primary hover:text-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M3 5a2 2 0 00-2 2v6a2 2 0 002 2h4v2h6v-2h4a2 2 0 002-2V7a2 2 0 00-2-2H3zm2 2a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1h1zm11 0h1a1 1 0 011 1v2a1 1 0 01-1 1h-1a1 1 0 01-1-1V8a1 1 0 011-1zM9 10a1 1 0 100-2h2a1 1 0 100 2H9z"/>
                </svg>
                <span>Write article</span>
              </button>
            </div>
          </section>

          <div>
            <div v-for="post in suggestedPosts" :key="post.id" class="bg-white shadow p-4 mb-4 rounded-xl">
              <h3 class="text-lg font-semibold mb-2">{{ post.title }}</h3>
              <img :src="post.imageUrl" :alt="post.title" class="pb-2 w-full h-80 object-cover">
              <div class="flex justify-between items-center w-1/4">
                <button class="text-primary font-bold">Like</button>
                <button class="text-primary font-bold">Comment</button>
                <button class="text-primary font-bold">Repost</button>
                <button class="text-primary font-bold">Send</button>
              </div>
            </div>
          </div>
        </main>

        <!-- Right Sidebar -->
        <aside class="w-64 bg-gray-100 p-4">
          <h2 class="text-lg font-semibold mb-4">Add to your feed</h2>
          <ul>
            <li v-for="company in companies" :key="company.id" class="mb-4">
              <div class="flex items-center space-x-2">
                <img :src="company.logo" :alt="company.name" class="rounded-full h-10">
                <div>
                  <h3 class="text-md font-semibold">{{ company.name }}</h3>
                  <button class="text-primary font-bold">+ Follow</button>
                </div>
              </div>
            </li>
          </ul>
        </aside>
      </div>
    </div>
  </template>

  <script setup lang="ts">
  import { ref } from 'vue';

  interface Post {
    id: number;
    title: string;
    imageUrl: string;
  }

  interface Company {
    id: number;
    name: string;
    logo: string;
  }

  const suggestedPosts = ref<Post[]>([
    { id: 1, title: "Unitree's G1 Humanoid Agent", imageUrl: "/assets/images/house-with-white-roof-that-has-black-door.jpg" },
    { id: 2, title: "Discover Jakarta", imageUrl: "/assets/images/jakarta.jpg" },
    { id: 3, title: "Beautiful Apartment", imageUrl: "/assets/images/apartment 1.jpg" },
    { id: 4, title: "Beautiful Apartment", imageUrl: "/assets/images/Apartment 4.jpg" },
    { id: 5, title: "Beautiful Apartment", imageUrl: "/assets/images/apartment 2.jpg" },
    { id: 6, title: "Beautiful Apartment", imageUrl: "/assets/images/Apartment 3.jpg" },
    { id: 7, title: "Beautiful Apartment", imageUrl: "/assets/images/Apartment 4.jpg" },
  ]);

  const companies = ref<Company[]>([
    { id: 1, name: "Hitachi Energy", logo: "/assets/images/profile-11.jpeg" },
    { id: 2, name: "AWS Training & Certification", logo: "/assets/images/profile-35.png" },
    { id: 3, name: "Freelancer Robin", logo: "/assets/images/profile-34.jpeg" },
    { id: 1, name: "Hitachi Energy", logo: "/assets/images/profile-11.jpeg" },
    { id: 3, name: "Freelancer Robin", logo: "/assets/images/profile-34.jpeg" },
    { id: 2, name: "AWS Training & Certification", logo: "/assets/images/profile-35.png" },

  ]);
  </script>

  <style>

  </style>

  <style scoped>


  </style>
