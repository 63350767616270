<template>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" class="relative z-50" @close="close">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex items-center justify-center min-h-full p-4 text-center">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-md p-6 rounded-2xl shadow-lg" style="background-color: rgba(255, 255, 255, 0.8);">
                <div class="flex justify-between items-center">
                  <h3 class="text-lg font-medium text-gray-900">Try demo version</h3>
                  <button @click="close" class="text-primary hover:text-gray-500">
                    <span class="sr-only">Close</span>
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    You can try the demo version by signing up below
                  </p>
                </div>
                <div class="mt-4">
                  <label for="name" class="block text-start text-sm font-medium text-gray-700">Full name</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Your full name"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    v-model="name"
                  >
                </div>
                <div class="mt-4">
                  <label for="phone" class="block text-start text-sm font-medium text-gray-700">Phone number</label>
                  <input
                    type="text"
                    id="phone"
                    placeholder="Phone number"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    v-model="phone"
                  >
                </div>
                <div class="mt-4">
                  <label for="email" class="block text-start text-sm font-medium text-gray-700">E-mail</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Your E-mail"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    v-model="email"
                  >
                </div>
                <div class="mt-4">
                  <label for="password" class="block text-start text-sm font-medium text-gray-700">Password</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Password"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    v-model="password"
                  >
                </div>
                <div class="mt-4">
                  <label for="repassword" class="block text-start text-sm font-medium text-gray-700">Re-enter password</label>
                  <input
                    type="password"
                    id="repassword"
                    placeholder="Re-enter password"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    v-model="repassword"
                  >
                </div>
                <div class="mt-4">
                  <label for="company" class="block text-start text-sm font-medium text-gray-700">Company name</label>
                  <input
                    type="text"
                    id="company"
                    placeholder="Company name"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    v-model="company"
                  >
                </div>
                <div class="mt-4 flex items-center">
                  <input type="checkbox" id="agree" v-model="agree" class="mr-2 mb-">
                  <label for="agree" class="text-sm text-gray-700">
                    I've read and agree with <a href="#" class="text-primary">terms of service</a> and <a href="#" class="text-primary">privacy policy</a>
                  </label>
                </div>
                <div class="mt-6 flex justify-start">
                  <button
                    @click="sendMessage"
                    class="px-4 py-2 w-full text-white bg-primary rounded-md"
                    :disabled="loading"
                  >
                  <span v-if="loading">Loading...</span>
                   <span v-else>Sign Up</span>
                  </button>
                </div>
                <div class="mt-4 text-center">
                  <p class="text-sm text-gray-700">Already have an account? <NuxtLink to="/auth/cover-login" class="text-primary">Sign in</NuxtLink></p>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>

  <script lang="ts" setup>
  import { ref } from 'vue';
  import { Dialog, DialogOverlay, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
  import { createCrmLeadPublic, registerCompany } from '~/server/api/apiService';
  import { showMessage } from '~/utils/index';

  const props = defineProps({
    isOpen: {
      type: Boolean,
      required: true
    }
  });

  const emit = defineEmits(['close']);

  const name = ref('');
  const phone = ref('');
  const email = ref('');
  const password = ref('');
  const repassword = ref('');
  const company = ref('');
  const agree = ref(false);
  const loading = ref(false);

  const close = () => {
    emit('close');
  };

  const sendMessage = async () => {
    if (!name.value) {
      showMessage('Full name is required', 'danger');
      return;
    }

    if (!phone.value) {
      showMessage('Phone number is required', 'danger');
      return;
    }

    if (!email.value) {
      showMessage('Email is required', 'danger');
      return;
    }

    if (!password.value) {
      showMessage('Password is required', 'danger');
      return;
    }

    if (password.value !== repassword.value) {
      showMessage('Passwords do not match', 'danger');
      return;
    }

    if (!company.value) {
      showMessage('Company name is required', 'danger');
      return;
    }

    if (!agree.value) {
      showMessage('You must agree to the terms of service and privacy policy', 'danger');
      return;
    }

    try {
        loading.value = true;
        const params = {
            city: "", // Or fetch from a form input if available
            company_name: company.value,
            name: name.value,
            company_registry: name.value, // Or fetch from a form input if available
            country_id: 100, // Replace with actual country ID
            date_register: new Date().toISOString().split('T')[0], // Current date in YYYY-MM-DD format
            email: email.value,
            is_trial_mode: true, // Or fetch from a form input if available
            mobile: phone.value,
            password: password.value,
            phone: phone.value,
            state_id: 0, // Replace with actual state ID
            street: "", // Or fetch from a form input if available
            street2: "", // Or fetch from a form input if available
            vat: "", // Or fetch from a form input if available
            website: "", // Or fetch from a form input if available
            zip: "" // Or fetch from a form input if available
        };
      const response = await registerCompany(params);
      console.log('Request Demo created:', response);

      showMessage('Request Demo created.', 'success');

      close();
    } catch (error) {
      loading.value = false;
      console.error('Error creating Request Demo:', error);
      showMessage('Failed to create Request Demo. Please try again.', 'danger');
    }finally{
        loading.value = false;
    }
  }
  </script>

  <style scoped>
  /* Custom styles here if needed */
  </style>
